<template>
  <div>
    <v-observer tag="form" ref="formShippingInformation" class="form-rounded-inputs" @submit.prevent="fnValidateOwnTrackingNumber()">
      <div class="row">
        <div class="col-12 col-lg-6 ">
          <v-validation :rules=" carriersList.length > 0 ? 'required' : ''" v-slot="{ errors }"
            :name=" $t('general.form.carrier') ">
            <div class="form-group mb-1 ">
              <label for="" v-text="  $t('general.form.carrier') "> </label>
              <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                  v-model="ownTrackingNumber.carrier">
                  <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                  <option :value="carrier.name" v-for=" (carrier,index) in  carriersList " :key="index"
                    v-text=" carrier.name.toUpperCase() ">
                  </option>
                </select>
                <div class="input-group-append">
                  <label class="input-group-text"> <i class="fa fa-shipping-fast "></i> </label>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>
        </div>

        <div class="col-12 col-lg-6 ">
          <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.trackingNumber') ">
            <div class="form-group mb-1">
              <label for="" v-text="$t('general.form.trackingNumber')"> </label>
              <div class="input-group ">
                <input type="text" class="form-control" v-model="ownTrackingNumber.tracking_number"
                  placeholder="AR1234567890" :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="input-group-append">
                  <span class="input-group-text rounded-right"> <strong>
                      <i class=" fa fa-keyboard "></i>
                    </strong> </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
              <!-- <input type="text" v-model=" ownTrackingNumber.tracking_number " placeholder="AR1234567890"
                    class="form-control" :class=" { 'is-invalid':errors.length > 0 } ">
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div> -->
            </div>
          </v-validation>
        </div>

      </div>
      <hr>
      <div class="card bg-warning mb-3">
        <div class="card-body">
          <p>
            <strong>
              <i class="fa fa-exclamation-triangle  mr-1 "></i>
              <span v-text="$t('dashboard.order.shipping.warning')"></span>
            </strong>
            <br>
            <span v-text="$t('dashboard.order.shipping.ownShipmentWarningMessage')">
            </span>
          </p>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-auto">
          <button class="btn btn-light " type="button" @click="setNewTrackingType(null)" v-text="$t('general.button.cancel')">  </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-warning "> 
            <i class="fa fa-exclamation-triangle mr-1 "></i>
             <span v-text="$t('general.button.updateShipment')"></span> 
             </button>
        </div>
      </div>
      <input type="submit" hidden>
    </v-observer>

  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {

    data() {
      return ({
        ownTrackingNumber: {
          carrier: null,
          tracking_number: null,
        }
      })
    },
    computed: {
      ...mapState('order', ['carriersList','newTrackingType']),
    },
    methods: {
      ...mapMutations('order', ['setNewTrackingType']),
      ...mapActions('order', ['fnApiUpdateOwnTrackingNumber']),

      async fnValidateOwnTrackingNumber() {
        if (await this.$refs['formShippingInformation'].validate()) {
          this.fnApiUpdateOwnTrackingNumber(this.ownTrackingNumber);
        }
      },
    }
  }
</script>

<style>

</style>